
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}
.Title {
  color: $color-grey-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.08px;
  &.SmallTitle {
    font-size: 15px;
  }
  @each $name, $value in $color-rating-list {
    &.Rating-#{$name} {
      color: $value;
    }
  }
  &::first-letter {
    text-transform: uppercase;
  }
  .dark & {
    color: white;
  }
}
.BlackTitle {
  color: $color-common-black;
  font-weight: 700;
}
.Description {
  color: $color-grey-medium;
  font-size: 16px;
  line-height: 1.3;
  .dark & {
    color: white;
  }
}
.DotList {
  display: flex;
  column-gap: 8px;
  @include mq($breakpoint-desktop) {
    column-gap: 4px;
  }
}
.Dot {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  @each $name, $value in $color-rating-list {
    &.Rating-#{$name} {
      background-color: $value;
    }
  }
  &.GreyDot {
    background-color: $color-grey-light;
  }
 
  @include mq($breakpoint-desktopMedium) {
    width: 18px;
    height: 18px;
  }
  @include mq($breakpoint-desktopLarge) {
    width: 20px;
    height: 20px;
  }
}
