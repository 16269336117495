
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AccordionWrap {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 1px solid $color-grey-light;
  width: 100%;
  &.AccordionWrapNoBottomBorder, &.ActiveAccordionWrap {
    border-bottom: none;

  }

}
.ActiveAccordionWrap + .AccordionContent {
  border-bottom: 1px solid $color-grey-light;
 
}
.AccordionHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.AccordionHeadingTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: $color-primary-teal;
  font-family: $font-heading;
}
.AccordionContent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  @include mq($breakpoint-desktop) {
    row-gap: 24px;
  }
  &.ActiveAccordionContent {
    // box-sizing: content-box;
    padding-bottom: 16px;
     @include mq($breakpoint-desktop) {
    padding-bottom: 24px;
  }
  }
}
.ArrowIcon {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;

  &.ActiveArrowIcon {
    transform: rotate(0deg);
  }
}
