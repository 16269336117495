
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  font-family: $font-heading;
  display: none;
  font-size: 24px;
  color: $color-primary-teal;
  margin-bottom: 24px;
  @include mq($breakpoint-desktop) {
    display: block;
  }
}
.OverviewList {
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  display: none;
  column-gap: 16px;
  &.MobileOverviewList {
    display: grid;
  }
  @include mq($breakpoint-desktop) {
    display: grid;
    row-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}
.OverViewList1Col {
  grid-template-columns: repeat(1, 1fr);
}
.AdditionalIssues {
  color: $color-common-black;
  font-weight: 700;
}
.AdditionalIssuesDescription {
  color: $color-grey-medium;
  font-size: 16px;
  line-height: 1.3;
}